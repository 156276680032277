<template>
  <div class="top5_main">
    <blockTitle>Top 5</blockTitle>
    <div class="overviewcomponent top5">
      <OverviewTable contentName="Top5" />
    </div>
  </div>
</template>

<script>
import OverviewTable from "@/components/OverviewTable.vue";

export default {
  components: {
    OverviewTable
  },
  data() {
    return {
    }
  },
}
</script>

<style scoped>
@import "../assets/overview/overview.css";

.top5_main {
  margin-top: 5px;
  width: 100%;
}

.top5 {
  background-image: url(../assets/top5_bg_small.png);
}
</style>