<template>
 <div class="register">
  <pageTitle>{{ t('Passwort zurücksetzen') }}</pageTitle>
    <b-form @submit="onSubmit" v-if="show">
      <b-form-group
        id="input-email-group"
        :label="textMail + ':'"
        label-for="input-email"
      >
        <b-form-input
          id="input-email"
          v-model="form.email"
          type="email"
          required
          :placeholder="textPlaceholderMail"
        ></b-form-input>
      </b-form-group>

      <b-button type="submit" variant="primary">{{ t('Passwort zurücksetzen') }}</b-button>
    </b-form>
 </div>
</template>

<script>
import { userDataFormMixin } from "@/mixins/userDataFormMixin"
import { translationMixin } from "@/mixins/translationMixin.js";
import { translationTableMixin} from "@/mixins/translations/views/User-Management/UserManagement.js";

  export default {
    mixins: [translationMixin, translationTableMixin, userDataFormMixin],
    data() {
      return {
        form: {
          email: ''
        }
      }
    },
    computed: {
      textMail() {
        return this.t('E-Mail Adresse');
      },

      textPlaceholderMail() {
        return this.t('Bitte E-Mail Adresse eingeben');
      },
    },
    created() {
      this.action = 'requestPasswordReset';
      this.successMessage = this.t('successMessageResetPW') + '!';
      this.failureMessage = this.t('errorMessageResetPW') + ':';
      this.goToPreviousPageOnSuccess = true;
    },
    methods: {
      clearData() {
        this.form.email = '';
      },
      submissionOk() {
        return true;
      },
    }
  }
</script>

<style scoped>
.resetPassword {
  margin-bottom: 5px;
  font-size: small;
}
</style>