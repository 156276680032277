import InitialRaceState from "@/js-code/states/initial_race";

export default class Race {
  constructor() {
    this.race = 'current';
    this.currentRace = 'unbekannt';

    this.initialRaceState = new InitialRaceState(() => window.location.reload());
    window.setInterval(this.checkRaceUpdate.bind(this), 30000);
  }

  setStoreContext(storeContext)
  {
    this.storeContext = storeContext;
  }

  async checkRaceUpdateAsync()
  {
    if (this.race === 'current') {
      const backend = this.storeContext.rootState.backend;
      const result = await backend.get(process.env.VUE_APP_BACKEND_URL + '/getRace');
      this.initialRaceState.checkUpdate(result.data);
    }
  }

  checkRaceUpdate()
  {
    this.checkRaceUpdateAsync();
  }

  async determineCurrent()
  {
    const backend = this.storeContext.rootState.backend;
    const result = await backend.get(process.env.VUE_APP_BACKEND_URL + '/getRace');
    if (result.data !== undefined && result.data.race !== undefined)
      this.currentRace = result.data.race;      
  }

  getCurrent() {
    return this.currentRace;
  }

  resolve() {
    return this.getCurrent();
  }

  asParameter() {
    return this.race;
  }
}
