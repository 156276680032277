import RealtimeClient from "./realtime_node.js";
import TickerState from "@/js-code/states/ticker";

export default class TickerClient {
  constructor(storeContext) {
    this.storeContext = storeContext;
    this.race = this.storeContext.rootGetters['race/currentParam'];
    if (this.race === undefined)
      this.race = 'Test!';
    this.maxItems = this.storeContext.getters['maxItems'];
    this.specifier = {race : this.race, maxEntries : this.maxItems};

    this.state = new TickerState(this.race, this.maxItems, this.getItems.bind(this), this.setItems.bind(this));

    this.storeContext.commit('race/addObserver', this.state, { root: true });

    this.client = new RealtimeClient('/ticker', this, this.getBackend.bind(this));
  }

  setItems(items)
  {
    this.storeContext.commit('setItems', items);
  }

  getItems() {
    return this.storeContext.getters['items'];
  }

  getBackend()
  {
    return this.storeContext.rootState.backend;
  }

  async testUpdate()
  {
    let testStateIndicator = this.getItems()[3].id;

    return await this.client.getUpdates(this.specifier, testStateIndicator);
  }

  getStandardSpecifier()
  {
    return this.specifier;
  }

  updateAll(specifier, stateIndicator, content)
  {
    this.state.updateAll(specifier, stateIndicator, content);
  }

  merge(specifier, previousIndicator, currentIndicator, content)
  {
    return this.state.merge(specifier, previousIndicator, currentIndicator, content);
  }

  handleError(e)
  {
    console.log(e);
  }
}