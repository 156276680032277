<template>
  <div>
    <div class="buttonContainer">
      <b-button v-b-toggle.comparison-sector variant="primary"><b-icon icon="bar-chart-fill" variant="warning" /> {{ (!formVisible) ? t('Vergleichen') : t('Vergleich schließen') }}</b-button>
    </div>
    <b-collapse :visible="false" v-model="formVisible" id="comparison-sector">
      <div style="align-content: center; text-align: center;">
        <b-form-radio-group v-model="formType" :options="formTypeOptions" buttons>
        </b-form-radio-group>
      </div>

      <div class="comparisonForm">
        <b-input-group class="mt-3" v-if="parseInt(formType) === 0">
          <b-form-select v-model="comparison.competitors.heat" :options="heatOptions">
            <template v-slot:first>
              <b-form-select-option :value="null" disabled>{{ translateTableHead('Lauf') }}</b-form-select-option>
            </template>
          </b-form-select>

          <b-form-select v-model="comparison.competitors.competitor1" :options="competitorOptions">
            <template v-slot:first>
              <b-form-select-option :value="null" disabled>{{ translateTableHead('Fahrer') }} 1</b-form-select-option>
            </template>
          </b-form-select>
          <b-form-select v-model="comparison.competitors.competitor2" :options="competitorOptions">
            <template v-slot:first>
              <b-form-select-option :value="null" disabled>{{ translateTableHead('Fahrer') }} 2</b-form-select-option>
            </template>
          </b-form-select>
        </b-input-group>
        <b-input-group class="mt-3" v-else>
          <b-form-select v-model="comparison.heats.competitor" :options="competitorOptions">
            <template v-slot:first>
              <b-form-select-option :value="null" disabled>{{ translateTableHead('Fahrer') }}</b-form-select-option>
            </template>
          </b-form-select>

          <b-form-select v-model="comparison.heats.heat1" :options="heatOptions">
            <template v-slot:first>
              <b-form-select-option :value="null" disabled>{{ translateTableHead('Lauf') }} 1</b-form-select-option>
            </template>
          </b-form-select>
          <b-form-select v-model="comparison.heats.heat2" :options="heatOptions">
            <template v-slot:first>
              <b-form-select-option :value="null" disabled>{{ translateTableHead('Lauf') }} 2</b-form-select-option>
            </template>
          </b-form-select>
        </b-input-group>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import ClassificationHelper from "@/js-code/classification_helper.js";
import { translationMixin } from "@/mixins/translationMixin.js";
import { translationTableMixin} from "@/mixins/translations/views/Results.js";
import { timingTranslationMixin } from "@/mixins/translations/live-timing/live-timing.js";

export default {
  mixins: [translationMixin, translationTableMixin, timingTranslationMixin],
  data() {
    return {
      comparison : {
        competitors : {
          heat: null,
          competitor1: null,
          competitor2: null
        },
        heats : {
          competitor: null,
          heat1: null,
          heat2: null
        }
      },
      formVisible : false,
      formType : 0,
      formTypeOptions : [{ text: "Zwei Fahrer in einem Lauf", value: 0}, {text : "Zwei Läufe eines Fahrers", value: 1}],
    }
  },
  props: ["currentContent", "setComparison"],
  computed: {
    sectorData() {
      if (this.currentContent && this.currentContent.groups)
      {
        return ClassificationHelper.extractSectorData(this.currentContent);
      }
      else
        return [];
    },
    heatOptions() {
      if (this.sectorData.length > 0)
        return this.sectorData[0].heats.map(function (x, index) {
          return {value: index, text: this.translateTableHead(x.name)};
         }, this);
      else
        return [];
    },
    competitorOptions() {
      let result = [];
      for (let entry of this.sectorData)
        result.push({value: entry.ID, text: entry.title});
      return result;
    },
    finalComparison() {
      if (this.formType === 0) {
        return this.comparison.competitors;
      }
      return this.comparison.heats;
    }
  },
  mounted() {
    this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
      if (collapseId === 'comparison-sector')
        this.setComparison(isJustShown ? this.finalComparison : null);
    })
  },
  watch: {
    comparison: {
      handler(val) {
        this.setComparison(this.finalComparison);
      },
      deep: true
    },
    formType: {
      handler(val) {
        this.setComparison(this.finalComparison);
      },
    }
  }
}
</script>

<style scoped>
.comparisonForm {
  display: flex;
  padding: 10px;
}

.buttonContainer {
  text-align: center;
  justify-content: space-around;
  align-items: center;
  flex-flow: row wrap;
  padding: 15px;
}
</style>
