import LiveTimingClient from "../js-code/livetiming.js"

var moduleLiveTiming = {
  namespaced: true,

  state: {
    client: null,

    // results are mapped in terms of an associative array
    // e.g. state.results['Top5'] contains a JSON-string encoding the result
    results: [],

    notSelectableResults: ["Top5", "Last5", "Ontrack", "RecentAborts", "NextStarters", "Teilnehmer"],  

    eventState: ''
  },

  getters: {
    results: (state) => (type) => {
      return (state.results[type] !== undefined) ? state.results[type] : null;
    },

    eventState: (state) => {
      return state.eventState;
    },

    availableClassifications: (state) => {
      let result = [];
      for (let cls in state.results)
        if (state.notSelectableResults.indexOf(cls) === -1)
          result.push(cls);
      return result;
    }
  },

  mutations: {
    setClient(state, value) {
      state.client = value;
    },

    setResults(state, value) {
      if (value !== null && value !== undefined && value.results)
      {
        //console.log(value.results);
        let tempResults = [];
        for (let result of value.results)
          tempResults[result.Name] = result.Content;

        state.results = tempResults;

        state.eventState = value.eventState;
      }
    },

    setEventState: function (state, value) {
      if (value !== null && value !== undefined)
      {
        state.eventState = value;
      }
    },

    updateAll: function (state) {
      if (state.client)
        state.client.updateAllResults();
    }
  },

  actions: {
    async init(context) {
      context.commit('setClient', new LiveTimingClient(context));
    },

    async updateResults(context, onNotificationSent) {
      const temp = await context.state.client.updateResults();
      onNotificationSent(temp);
    },

    async updateCache(context, onNotificationSent) {
      const temp = await context.state.client.updateCache();
      onNotificationSent(temp);
    },

    async restartClassification(context, onNotificationSent) {
      const temp = await context.state.client.restartClassification();
      onNotificationSent(temp);
    },

    async checkCurrentEvents(context, onNotificationSent) {
      const temp = await context.state.client.checkCurrentEvents();
      onNotificationSent(temp);
    },
  },
}

export {
  moduleLiveTiming
}