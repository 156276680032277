import RealtimeClient from "./realtime_node.js";
import ChatState from "./states/chat";

export default class ChatClient {
  constructor(storeContext) {
    this.storeContext = storeContext;
    this.state = new ChatState(this.getItems.bind(this), this.setItems.bind(this), this.getOldestID.bind(this));
    this.specifier = {};
    this.namespace = '/chat';

    this.client = new RealtimeClient(this.namespace, this, this.getBackend.bind(this));
    this.refreshCounter();
    this.interval = window.setInterval(this.takeIntervalActions.bind(this), 60 * 1000);
  }

  getBackend()
  {
    return this.storeContext.rootState.backend;
  }

  getItems()
  {
    return this.storeContext.getters['items'];
  }

  setItems(newItems)
  {
    this.storeContext.commit('setItems', newItems)
  }

  beforeDestroy()
  {
    window.clearInterval(this.interval);
  }

  getStandardSpecifier()
  {
    return this.specifier;
  }

  updateAll(specifier, stateIndicator, content)
  {
    this.state.updateAll(specifier, stateIndicator, content);
  }

  merge(specifier, previousIndicator, currentIndicator, content)
  {
    return this.state.merge(specifier, previousIndicator, currentIndicator, content);
  }

  handleError(e)
  {
    console.log(e);
  }

  async postEntry(author, msg)
  {
    const backend = this.getBackend();
    return await backend.post(process.env.VUE_APP_BACKEND_URL + this.namespace + '/postEntry', {author : author, message: msg});
  }

  async blockUser(trackingID)
  {
    const backend = this.getBackend();
    return await backend.post(process.env.VUE_APP_BACKEND_URL + this.namespace + '/blockUser', {trackingID : trackingID});
  }

  async getOldestID()
  {
    const backend = this.getBackend();
    return await backend.get(process.env.VUE_APP_BACKEND_URL + this.namespace + '/getOldestID');
  }

  async refreshCounter() 
  {
    if (this.storeContext.rootState.currentRouteName === "chat")
    {
      //console.log(new Date(Date.now()));
      //console.log('refreshCounter');
      const backend = this.getBackend();
      backend.get(process.env.VUE_APP_BACKEND_URL + this.namespace + '/refreshCounter');
    }
  }

  async takeIntervalActions()
  {
    this.state.ajaxFilterItems();
    this.refreshCounter();
  }
}