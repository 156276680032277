<template>
 <div class="register">
  <pageTitle>{{ t('Daten ändern') }}</pageTitle>
    <b-form @submit="onSubmit" @reset="onReset" v-if="show">
      <b-form-group 
        id="input-group-username" 
        :label="textUsername + ':'"
        label-for="input-username"
        :description="textDescriptionUsername+'.'">
        <b-form-input
          id="input-username"
          v-model="form.name"
          required
          :state="validation"
          :placeholder="textPlaceholderUsername"
        ></b-form-input>
        <b-form-invalid-feedback :state="validation">{{ t('Name darf nicht leer sein') }}.</b-form-invalid-feedback>
      </b-form-group>

      <b-button type="submit" variant="primary">{{ t('Daten ändern') }}</b-button>
      <b-button type="reset" variant="danger">{{ t('Reset') }}</b-button>
    </b-form>
 </div>
</template>

<script>
import { userDataFormMixin } from "@/mixins/userDataFormMixin"
import { translationMixin } from "@/mixins/translationMixin.js";
import { translationTableMixin} from "@/mixins/translations/views/User-Management/UserManagement.js";

  export default {
    mixins: [translationMixin, translationTableMixin, userDataFormMixin],
    data() {
      return {
        form: {
          name: ''
        }
      }
    },
    computed: {
      validation() {
        return this.form.name.length > 0;
      },

      textUsername() {
        return this.t('Benutzername');
      },

      textPlaceholderUsername() {
        return this.t('Bitte Benutzernamen eingeben');
      },

      textDescriptionUsername() {
        return this.t('Wird im Chat angezeigt');
      },
    },
    created() {
      this.action = 'changeData';
      this.successMessage = this.t('successMessageChangeData') + '!';
      this.failureMessage = this.t('errorMessageChangeData') + ':';
      this.goToPreviousPageOnSuccess = true;

      this.form.name = this.$store.getters['userManagement/userName'];
    },
    methods: {
      clearData() {
        this.form.name = this.$store.getters['userManagement/userName'];
      },
      submissionOk() {
        return this.validation;
      },
    }
  }
</script>

<style scoped>
</style>