<template>
  <div class="overviewcomponent starting">
   <table>
     <tr>
       <td style="width: 20px">
          <div class="img">
            <div><img src="../../assets/overview/start.png" /></div>
          </div>
       </td>
       <td style="width: 180px;">
         <OverviewTable contentName="NextStarters" />
       </td>
     </tr>
   </table>
  </div>
</template>

<script>
import OverviewTable from "@/components/OverviewTable.vue";

export default {
  components: {
    OverviewTable
  },
  data() {
    return {
    }
  },
}
</script>

<style scoped>
@import "../../assets/overview/overview.css";

.starting {
  background-image: url(../../assets/overview/start_bg.png);
}
</style>