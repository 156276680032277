export const liveTimingGroupSelectionMixin = {
  data() {
    return {
      selectedGroup: null
    }
  },
  computed: {
    groupOptions() {
      let result = [{value: null, text: '-- ' + this.t('alle') + ' --'}];
      for (let x in this.currentContent.groups)
      {
        const currentTitle = this.translateGroupTitle(this.resolveText(this.currentContent.groups[x].content));
        if (currentTitle.trim() !== '')
          result.push({value: x, text: currentTitle});
      }
      return result;
    }
  }
};