export default class LivetimingHelper {
  static extractText(content)
  {
    if (!content)
      return '';
    else
    {
      let result = '';
      for (let cell of content.elements)
      {
        if (cell.units)
        {
          for (let u of cell.units)
            if (u.unitType === "text")
              result = result + u.text;
        }
        else if (cell.text)
          result = result + cell.text;
      }
      return result;
    }
  }

  static toString(value) {
    if (value === null || typeof value === 'undefined') {
      return ''
    }
    else if (value instanceof Object) {
      return Object.keys(value)
          .sort()
          .map(key => LivetimingHelper.toString(value[key]))
          .join(' ')
    }
    else {
      return String(value)
    }
  }

  static sortCompare(aBase, bBase, compareOptions, compareLocale)
  {
    const a = LivetimingHelper.extractText({ elements: [aBase] });
    const b = LivetimingHelper.extractText({ elements: [bBase] });
    const aInt = parseInt(a, 10);
    const bInt = parseInt(b, 10);
    if (!isNaN(aInt) && !isNaN(bInt))
    {
      return (aInt < bInt ? -1 : aInt > bInt ? 1 : 0);;
    }
    else if (!isNaN(aInt))
    {
      return -1;
    }
    else if (!isNaN(bInt))
    {
      return 1;
    }
    else
    {
      return LivetimingHelper.toString(a).localeCompare(LivetimingHelper.toString(b), compareLocale, compareOptions);
    }
  }

  static extractItems(datasets, primaryKeySource)
  {
    let result = [];
    if (datasets)
    {
      for (let ds of datasets)
        for (let r of ds.rows)
        {
          let row = {};
          for (let i in r.cells)
          {
            const key = "c" + i;
            row[key] = r.cells[i];
            if (key === primaryKeySource)
              row["ID"] = LivetimingHelper.extractText({ elements: [r.cells[i]] });
          }
          result.push(row);
        }
    }
    return result;
  }

  static extractFieldDefinitions(contentHead, translator)
  {
    let result = [];
    /*let fieldDefinition = {
      key: "ID",
      label: "ID"
    }
    result.push(fieldDefinition);*/
    //console.log(this.contentHead.rows);
    for (let r of contentHead.rows)
    {
      for (let i in r.cells)
      {
        const title = translator(this.extractText({ elements: [r.cells[i]]}).trim());
        const key = "c"+i;
        let fieldDefinition = {
          key: key,
          label: title,
          sortable: r.cells[i].sortable,
          myOwnMetaInfo: r.cells[i].meta
        };

        fieldDefinition.class = [];
        fieldDefinition.tdClass = [];
        if (r.cells[i].htmlClass && r.cells[i].htmlClass.trim() !== '')
          fieldDefinition.class.push(r.cells[i].htmlClass);
        if (r.cells[i].meta && r.cells[i].meta.trim() !== '')
          fieldDefinition.class.push('meta_' + r.cells[i].meta);
        result.push(fieldDefinition);

        //console.log(fieldDefinition);
      }
    }
    return result;
  }

  static getOverviewTableData(content, maxRows, desc)
  {
    if (!content)
      return null;
    let result = {};
    result.head = content.head;
    result.meta = [];
    for (let r of result.head.rows)
      for (let c of r.cells)
        if (c.meta)
          result.meta.push(c.meta.trim());
        else
          result.meta.push('');
    result.body = {};
    result.body.rows = [];
    if (content.datasets)
    {
      for (let ds of content.datasets)
        for (let r of ds.rows)
        {
          if (maxRows === 0 || result.body.rows.length < maxRows)
            result.body.rows.push(r);
        }
    }
    if (desc)
      result.body.rows = result.body.rows.reverse();
    return result;
  }

  static parseJSON(content, OnError)
  {
    //console.log(content);
    if (content && content.length > 0)
      try {
        return JSON.parse(content);
      }
      catch (e) {
        OnError();
        return {};
      }
    else
      return {};
  }

  static extractOverviewTable(jsonString, OnError)
  {
    const result = LivetimingHelper.parseJSON(jsonString, OnError);
    let table = null;
    if (result && Object.keys(result).length > 0 && result.groups && result.groups.length > 0 && result.groups[0].subGroups.length > 0)
      table = result.groups[0].subGroups[0].table;
    return table;
  }
}