export default class InitialRaceState {
  constructor(onRaceChanged)
  {
    this.onRaceChanged = onRaceChanged;

    this.initialRace = null;
  }

  checkUpdate(data)
  {
    if (data !== undefined && data.race !== undefined) {
      const race = data.race;
      if (this.initialRace === null)
        this.initialRace = race;
      else if (this.initialRace !== race) {
        //console.log('reload: ' + this.initialRace + ' --> ' + race);
        this.onRaceChanged();
      } else {
        //console.log('same race: ' + this.initialRace);
      }
    }
  }
}