import RealtimeClient from "./realtime_node.js";
import LivetimingState from "./states/livetiming";

export default class LiveTimingClient {
  constructor(storeContext) {
    this.storeContext = storeContext;
    this.namespace = '/livetiming';
    this.race = this.storeContext.rootGetters['race/currentParam'];

    this.state = new LivetimingState(this.race, this.setResults.bind(this));
    this.updatingAll = false;

    this.storeContext.commit('race/addObserver', this.state, { root: true });

    this.client = new RealtimeClient(this.namespace, this, this.getBackend.bind(this));
    window.setInterval(this.updateEventStatus.bind(this), 30000);
  }

  setResults(results)
  {
    this.storeContext.commit('setResults', results);
  }

  getBackend()
  {
    return this.storeContext.rootState.backend;
  }

  getStandardSpecifier()
  {
    return {race: this.race};
  }

  updateAll(specifier, stateIndicator, content)
  {
    this.state.updateAll(specifier, stateIndicator, content);
  }

  merge(specifier, previousIndicator, currentIndicator, content)
  {
    return this.state.merge(specifier, previousIndicator, currentIndicator, content);
  }

  async updateEventStatus() {
    const backend = this.storeContext.rootState.backend;

    const result = await backend.get(process.env.VUE_APP_BACKEND_URL + this.namespace + '/getEventState');

    if (result.status === 'ok' && result.data.status === 'ok')
      this.storeContext.commit('setEventState', result.data.data);
    else
      this.storeContext.commit('setEventState', 'internet');
  }

  async updateAllResults() {
    //console.log('updateAllResults');
    if (!this.updatingAll) {
      this.updatingAll = true;
      try {
        const result = await this.client.getAll(this.state.specifier);

        this.state.updateState(result.content, result.stateIndicator)
      }
      finally {
        this.updatingAll = false;
      }
    }
  }

  async updateResults()
  {
    const backend = this.storeContext.rootState.backend;

    return await backend.get(process.env.VUE_APP_BACKEND_URL + this.namespace + '/updateResults');
  }

  async updateCache()
  {
    const backend = this.storeContext.rootState.backend;

    return await backend.get(process.env.VUE_APP_BACKEND_URL + this.namespace + '/updateCache');
  }

  async restartClassification()
  {
    const backend = this.storeContext.rootState.backend;

    return await backend.get(process.env.VUE_APP_BACKEND_URL + this.namespace + '/restartClassification');
  }

  async checkCurrentEvents()
  {
    const backend = this.storeContext.rootState.backend;

    return await backend.get(process.env.VUE_APP_BACKEND_URL + this.namespace + '/checkCurrentEvents');
  }

  handleError(e)
  {
    console.log(e);
  }
}