<template>
 <div class="register">
  <pageTitle>{{ t('Passwort zurücksetzen') }} (Schritt 2)</pageTitle>
    <b-form @submit="onSubmit" @reset="onReset" v-if="show">
      <b-form-group
        id="input-group-password2"
        :label="textNewPassword + ':'"
        label-for="input-password2"
      >
        <b-form-input
          id="input-password2"
          v-model="form.newPassword1"
          type="password"
          required
          :state="validation"
          :placeholder="textPlaceholderNewPassword"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="input-group-password3"
        :label="textNewPassword2 + ':'"
        label-for="input-password3"
      >
        <b-form-input
          id="input-password2"
          v-model="form.newPassword2"
          type="password"
          required
          :state="validation"
          :placeholder="textPlaceholderNewPassword2"
        ></b-form-input>
        <b-form-invalid-feedback :state="validation">{{ t('Beide Passwörter müssen übereinstimmen') }}.</b-form-invalid-feedback>
      </b-form-group>

      <b-button type="submit" variant="primary">{{ t('Passwort zurücksetzen') }}</b-button>
      <b-button type="reset" variant="danger">{{ t('Reset') }}</b-button>
    </b-form>
 </div>
</template>

<script>
import { userDataFormMixin } from "@/mixins/userDataFormMixin"
import { translationMixin } from "@/mixins/translationMixin.js";
import { translationTableMixin} from "@/mixins/translations/views/User-Management/UserManagement.js";

  export default {
    mixins: [translationMixin, translationTableMixin, userDataFormMixin],
    data() {
      return {
        form: {
          newPassword1: '',
          newPassword2: '',
          token: '',
          user_id: ''
        }
      }
    },
    computed: {
      validation() {
        return this.form.newPassword1 == this.form.newPassword2 && this.form.newPassword1.length > 0;
      },

      textNewPassword() {
        return this.t('Neues Passwort');
      },

      textPlaceholderNewPassword() {
        return this.t('Bitte neues Passwort eingeben');
      },

      textNewPassword2() {
        return this.t('Neues Passwort (Wiederholung)');
      },

      textPlaceholderNewPassword2() {
        return this.t('Bitte neues Passwort eingeben (nochmal)');
      },
    },
    created() {
      this.action = 'resetPassword';
      this.successMessage = this.t('successMessageResetPWStep2') + '!';
      this.failureMessage = this.t('errorMessageResetPWStep2') + ':';
      this.goToPreviousPageOnSuccess = true;

      this.form.token = this.$route.query.token;
      this.form.user_id = this.$route.query.user_id;
    },
    methods: {
      clearData() {
        this.form.newPassword1 = '';
        this.form.newPassword2 = '';
      },
      submissionOk() {
        return this.validation;
      },
    }
  }
</script>

<style scoped>
.resetPassword {
  margin-bottom: 5px;
  font-size: small;
}
</style>