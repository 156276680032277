<template>
  <span>
    <span v-if="content" :class="content.htmlClass">
      <span v-if="content.units"><LTUnit v-for="(u, index) in content.units" :key="'BU' + index" :translator="translator" :unit="u" :parentTblStacked="parentTblStacked" /></span>
      <span v-else-if="content.text !== undefined" :class="(content.text === '*') ? 'contains_only_star' : ''">{{ (translator) ? translator(content.text) : content.text }}</span>
    </span>
  </span>
</template>

<script>
export default {
  props: {
    "content" : {},
    "translator" : {
       default: null
     },
    "parentTblStacked" : {
      default: false
    },
  },
  beforeCreate() {
    this.$options.components.LTUnit = require("@/components/livetiming/LTUnit.vue").default;
  }
}
</script>

<style>
.group_title {
  margin-top: 20px;
  display: inline-block;
  width: 100%;
  text-align: center;
  font-weight: bold;
}
</style>