<template>
  <div class="overviewcomponent arrived">
   <table>
     <tr>
       <td style="width: 20px;" class="img">
         <div class="img">
            <div><img src="../../assets/overview/ziel.png" /></div>
          </div>
       </td>
       <td style="width: 180px;">
         <OverviewTable contentName="Last5" :desc="true" :separateLastCell="true" />
       </td>
     </tr>
   </table>
  </div>
</template>

<script>
import OverviewTable from "@/components/OverviewTable.vue";
import LivetimingHelper from "@/js-code/component-helpers/livetiming_helper";

export default {
  components: {
    OverviewTable
  },
  data() {
    return {
    }
  },
}
</script>

<style scoped>
@import "../../assets/overview/overview.css";

.arrived {
  background-image: url(../../assets/overview/kleine_flagge.png);
}
</style>