<template>
  <div class="results">
    <pageTitle>{{ t('Live-Timing') }}</pageTitle>
    <div class="clsList" v-if="standardClassifications && standardClassifications.length > 0">
      <span v-for="(c, index) in standardClassifications" :key="c">
        <span v-if="index > 0"> | </span>
        <span :style="(c === chosenCLS) ? 'font-weight: bold;' : ''"><a @click="setChoice(c)" style="cursor: pointer">{{ translateCLSTitle(c) }}</a></span>
      </span>
    </div>
    <div class="clsList" v-if="specialClassifications && specialClassifications.length > 0" style="padding-top: 3px;">
      <span v-for="(c, index) in specialClassifications" :key="c">
        <span v-if="index > 0"> | </span>
        <span :style="(c === chosenCLS) ? 'font-weight: bold;' : ''"><a @click="setChoice(c)" style="cursor: pointer">{{ translateCLSTitle(c) }}</a></span>
      </span>
    </div>
    <div class="clsGroupSelection" v-if="!emptyContent && currentContent.groups.length > 1">
      <b-form-select v-model="selectedGroup" :options="groupOptions"></b-form-select>
    </div>
    <div class="shadow" v-if="currentIsSectorCLS && !emptyContent">
      <LiveTimingComparison :currentContent="currentContent" :setComparison="setComparison" />
      <LiveTimingComparisonChart v-if="filterActive" :currentContent="filteredContent" />
    </div>
    <LiveTimingDocument :document="filteredContent" :selection="selectedGroup" />
  </div>
</template>

<script>
import ClassificationHelper from "@/js-code/classification_helper.js";
import LiveTimingDocument from "@/components/livetiming/LiveTimingDocument.vue";
import LiveTimingComparison from "@/components/livetiming/LiveTimingComparison.vue";
import LiveTimingComparisonChart from "@/components/livetiming/LiveTimingCompChart.vue";
import { liveTimingMixin } from "@/mixins/liveTimingMixin.js";
import { liveTimingGroupSelectionMixin } from "@/mixins/liveTimingGroupSelectionMixin.js";
import { translationMixin } from "@/mixins/translationMixin.js";
import { translationTableMixin} from "@/mixins/translations/views/Results.js";
import { timingTranslationMixin } from "@/mixins/translations/live-timing/live-timing.js";
import LivetimingHelper from "@/js-code/component-helpers/livetiming_helper";

export default {
  mixins: [translationMixin, translationTableMixin, liveTimingMixin, liveTimingGroupSelectionMixin, timingTranslationMixin],
  components: {
    LiveTimingDocument,
    LiveTimingComparison,
    LiveTimingComparisonChart
  },
  data() {
    return {
      currentCLS: null,
      selectedGroup: null,
      comparison : null,
      standardCLSNames : ["Gesamt", "Gruppen", "Klassen", "Live", "Sektoren"],
    }
  },
  methods: {
    setChoice(c) {
      this.currentCLS = c;
      this.selectedGroup = null;
    },
    setComparison(c) {
      this.comparison = c;
      //console.log('setComparison:');
      //console.log(c);
    },
  },
  computed: {
    availableClassifications() {
      return this.$store.getters['liveTiming/availableClassifications'];
    },
    standardClassifications() {
      let result = [];
      for (let cls of this.availableClassifications) {
        if (this.standardCLSNames.includes(cls))
          result.push(cls);
      }
      return result;
    },
    specialClassifications() {
      let result = [];
      for (let cls of this.availableClassifications) {
        if (!this.standardCLSNames.includes(cls))
          result.push(cls);
      }
      return result;
    },
    chosenCLS() {
      return (this.currentCLS != null) ? this.currentCLS :
             (this.findLive != null) ? this.findLive : 
             (this.availableClassifications.length > 0) ? this.availableClassifications[0] : null;
    },
    findLive() {
      let result = null;
      for (let cls of this.availableClassifications)
        if (cls === 'Live')
        {
          result = cls;
          break;
        }
      return result;
    },
    currentIsSectorCLS() {
      return this.chosenCLS === 'Sektoren';
    },
    currentContent() {
      const liveTimingJSONcode = this.$store.getters['liveTiming/results'](this.chosenCLS);
      return LivetimingHelper.parseJSON(liveTimingJSONcode, () => this.$store.commit('liveTiming/updateAll'));
    },
    emptyContent() {
      return !(this.currentContent && this.currentContent.groups && this.currentContent.groups.length > 0);
    },
    filterActive() {
      if (!this.comparison)
        return false;
      const competitorComparison =
          this.comparison.heat !== undefined && this.comparison.heat !== null
          && this.comparison.competitor1 !== undefined && this.comparison.competitor1 !== null
          && this.comparison.competitor2 !== undefined && this.comparison.competitor2 !== null;
      const heatComparison = this.comparison.competitor !== undefined && this.comparison.competitor !== null
          && this.comparison.heat1 !== undefined && this.comparison.heat1 !== null
          && this.comparison.heat2 !== undefined && this.comparison.heat2 !== null;

      return competitorComparison || heatComparison;
    },
    filteredContent() {
      if (this.currentIsSectorCLS && this.filterActive) {
        //console.log('filter active!');
        return ClassificationHelper.filterCLSContent(this.currentContent, this.comparison);
      }
      else {
        return this.currentContent;
      }
    }
  }
}
</script>

<style scoped>
.clsList {
  text-align: center;
}

.clsGroupSelection {
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
