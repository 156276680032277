export default class RaceDependentState {
  constructor(race)
  {
    this.race = race;
    this.stateIndicator = -1;
  }

  setCurrentRace(currentRace)
  {
    this.currentRace = currentRace;
  }

  specifierFitsCurrentRace(specifier)
  {
    return (specifier.race === this.race) || (this.race === 'current' && specifier.race === this.currentRace);
  }

  updateAll(specifier, stateIndicator, content)
  {
    if (this.specifierFitsCurrentRace(specifier))
    {
      this.stateIndicator = stateIndicator;
      this.updateContent(content);
    }
  }

  merge(specifier, previousIndicator, currentIndicator, content)
  {
    //console.log(content);
    //console.log(specifier);
    //console.log(this.race);
    if (this.specifierFitsCurrentRace(specifier))
    {
      //console.log(this.stateIndicator);
      //console.log(previousIndicator);
      //console.log(currentIndicator);
      if (previousIndicator === this.stateIndicator)
      {
        //console.log('merge!');
        if (!this.mergeContent(content))
          return {merged: false, stateIndicator: this.stateIndicator};
        this.stateIndicator = currentIndicator;
      }
      else
        return {merged: false, stateIndicator: this.stateIndicator};
    }

    return {merged: true};
  }
}