import NonRaceDependentState from "@/js-code/states/abstract/nonRaceDependentState";

export default class ChatState extends NonRaceDependentState {

  constructor(getItems, setItems, getOldestID) {
    super();
    this.getItems = getItems;
    this.setItems = setItems;
    this.getOldestID = getOldestID;

    this.stateIndicator = -1;
    this.lastFilterRun = new Date(0);
    this.filterInterval = 5 * 60000;
  }

  filter(items)
  {
    return items;
  }

  prepend(oldItems, newItems)
  {
    let result = oldItems;
    for (let element of newItems)
      result.unshift(element);
    return result;
  }

  filterOldItems(oldestID)
  {
    this.lastFilterRun = Date.now();
    const items = this.getItems();
    if (items.length > 0 && (oldestID === -1 || items[items.length-1].id < oldestID))
      this.setItems(items.filter(item => item.id >= oldestID && oldestID > -1));
  }

  updateContent(content)
  {
    this.setItems(this.filter(content.reverse()));
  }

  mergeContent(content)
  {
    //console.log('oldest id (merge): ' + content.oldestID);
    this.filterOldItems(content.oldestID);
    this.setItems(this.filter(this.prepend(this.getItems(), content.newItems)));

    return true;
  }

  async ajaxFilterItems()
  {
    if (this.lastFilterRun > Date.now() - this.filterInterval)
    {
      //console.log('cancelled filter');
      return false;
    }

    //console.log('execute filter');
    const result = await this.getOldestID();
    if (result.status === 'ok')
    {
      const oldestID = result.data.id;
      this.filterOldItems(oldestID);

      return true;
    }

    return false;
  }
}