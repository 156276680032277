import Race from "../js-code/race.js"

var moduleRace = {
  namespaced: true, 

  state: {
    race: new Race(),
    observers: []
  },

  getters: {
    resolve: state => {
      return state.race.resolve();
    },

    currentParam: state => {
      return state.race.asParameter();
    },
  },

  mutations: {
    set(state, value) {
      if (state.race.race != value)
        state.race.race = value;
    },

    addObserver(state, value) {
      state.observers.push(value);
      value.setCurrentRace(state.race.currentRace);
    }
  },

  actions: {
    async init(context) {
      context.state.race.setStoreContext(context);
      await context.state.race.determineCurrent();
      for (let observer of context.state.observers)
        observer.setCurrentRace(context.state.race.currentRace);
    },

    async refresh(context) {
      await context.state.race.determineCurrent();
      for (let observer of context.state.observers)
        observer.setCurrentRace(context.state.race.currentRace);
    },
  },
}

export {
  moduleRace
}