export default class Statistics {
  constructor(storeContext) {
    this.storeContext = storeContext;
    window.setInterval(this.updateCounters.bind(this), 30000);
    this.updateCounters();
  }

  async updateCountersAsync()
  {
    const backend = this.storeContext.rootState.backend;
    const result = await backend.get(process.env.VUE_APP_BACKEND_URL + '/statistics');
    if (result.status === 'ok')
      this.storeContext.commit('setCounter', result.data.counter);
  }

  updateCounters()
  {
    this.updateCountersAsync();
  }
}

