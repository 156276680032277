import MessageFormatter from "@/js-code/message_formatting";

export const translationMixin = {
  data() {
    return {
      translation : {
      }
    }
  },
  computed: {
    currentLanguage() {
      return this.$store.getters['language/current'];
    },
  },
  methods: {
    translate(text, map)
    {
      const langId = this.currentLanguage.id;

      return MessageFormatter.translateText(map, langId, text);
    },

    t(text) {
      return this.translate(text, this.translation);
    },
  },
}