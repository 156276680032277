export default class ClassificationHelper {

  static filterCLSContent(clsContent, filter) {
    var sectorIdx = -1;
    var idIdx = -1;

    let tempResult = {};
    tempResult = JSON.parse(JSON.stringify(clsContent));
    let currGroups = tempResult.groups;
    tempResult.groups = [];
    for (let group of currGroups)
    {
      let currSubGroups = group.subGroups;
      group.subGroups = [];
      for (let subGroup of currSubGroups)
      {
        let currentDatasets = subGroup.table.datasets;
        subGroup.table.datasets = [];

        if (!subGroup.table.head.rows[0])
          continue;

        if (sectorIdx === -1)
        {
          for (let [index, cell] of subGroup.table.head.rows[0].cells.entries())
          {
            if (cell.text === 'Sektoren')
            {
              sectorIdx = index;
            }
            else if (cell.meta === 'ID')
            {
              idIdx = index;
            }
        }

        if (sectorIdx === -1 || idIdx === -1)
          return [];
        }

        for (let field of ["competitor", "competitor1", "competitor2"]) {
          if (!filter[field])
            continue;

          for (let ds of currentDatasets) {
            let row = ds.rows[0];
            let ID = row.cells[idIdx].text;

            if (ID !== filter[field])
              continue;

            /*if (filter.competitor1 !== undefined && filter.competitor1 !== null && ID !== filter.competitor1 &&
                filter.competitor2 !== undefined && filter.competitor2 !== null && ID !== filter.competitor2)
              continue;

            if (filter.competitor !== undefined && filter.competitor !== null && ID !== filter.competitor)
              continue;*/

            const sectorTable = row.cells[sectorIdx].units[0].table;
            if (!sectorTable)
              continue;

            let rowSets = [sectorTable.head, sectorTable.datasets[0]];

            for (let rowSet of rowSets)
              for (let row of rowSet.rows) {
                let heatRows = [];
                for (let field of ["heat", "heat1", "heat2"]) {
                  if (filter[field] !== undefined && filter[field] !== null) {
                    heatRows.push(row.cells[filter[field] + 1]);
                  }
                }

                if (heatRows.length > 0) {
                  row.cells = [row.cells[0]];
                  for (let heatCells of heatRows)
                    row.cells.push(heatCells);
                }
              }

            subGroup.table.datasets.push(ds);
          }
        }
        if (subGroup.table.datasets.length > 0)
          group.subGroups.push(subGroup);
      }
      if (group.subGroups.length > 0)
        tempResult.groups.push(group);
    }
    //console.log(tempResult);

    return tempResult;
  }





  static extractSectorData(clsContent, filter = null)
  {
    var sectorIdx = -1;
    var idIdx = -1;
    var nameIdx = -1;

    let result = [];
    for (let group of clsContent.groups)
      for (let subGroup of group.subGroups)
      {

        if (!subGroup.table.head.rows[0])
          continue;

        if (sectorIdx === -1)
        {
          for (let [index, cell] of subGroup.table.head.rows[0].cells.entries())
          {
            if (cell.text === 'Sektoren')
            {
              sectorIdx = index;
            }
            else if (cell.meta === 'ID')
            {
              idIdx = index;
            }
            else if (cell.meta === 'name')
            {
              nameIdx = index;
            }
        }

        //console.log('secIdx: ' + sectorIdx);
        //console.log('idIdx: ' + idIdx);
        //console.log('nameIdx: ' + nameIdx);

        if (sectorIdx === -1 || idIdx === -1)
          return [];
        }

        for (let ds of subGroup.table.datasets)
          for (let row of ds.rows)
          {
            let entry = {
              ID: row.cells[idIdx].text,
              title: row.cells[idIdx].text + ( (nameIdx > -1) ? ' (' + row.cells[nameIdx].text + ')' : ''),
              sectorNames : [],
              heats : [],
              categories : [],
            };

            if (filter !== null && 
                filter.competitor1 !== undefined && filter.competitor1 !== null && entry.ID !== filter.competitor1 &&
                filter.competitor2 !== undefined && filter.competitor2 !== null && entry.ID !== filter.competitor2)
              continue;

            if (filter !== null &&
                filter.competitor !== undefined && filter.competitor !== null && entry.ID !== filter.competitor)
              continue;

            const sectorTableRows = row.cells[sectorIdx].units[0].table.datasets[0].rows;
            if (!sectorTableRows)
              continue;

            for (let [sIndex, sCell] of sectorTableRows[0].cells.entries())
              if (sIndex > 0)
              {
                //console.log(sCell);
                let heat = {
                  name : sCell.text,
                  data : [],
                }

                if (filter !== null && (filter.heat || filter.heat1 || filter.heat2) ) {
                  for (let field of ["heat", "heat1", "heat2"]) {
                    if (filter[field] !== undefined && filter[field] !== null && filter[field] === sIndex)
                      entry.heats.push(heat);
                  }
                }
                else {
                  entry.heats.push(heat);
                }
              }

            for (let [srIndex, sRow] of sectorTableRows.entries())
            {
              if (srIndex > 0)
              {
                for (let [sIndex, sCell] of sRow.cells.entries())
                {
                  //console.log(sIndex, sCell);
                  if (sIndex === 0)
                    entry.sectorNames.push(sCell.text);
                  else
                  {
                    let currHeatIdx = -1;
                    if (filter === null || filter.heat === null)
                      currHeatIdx = sIndex-1;
                    else if (filter.heat === sIndex || filter.heat1 === sIndex)
                      currHeatIdx = 0;
                    else if (filter.heat2 === sIndex)
                      currHeatIdx = 1;

                    entry.heats[currHeatIdx].data.push(sCell.text);

                    let lastEntryIdx = entry.heats[currHeatIdx].data.length - 1;

                    if (entry.categories.length <= lastEntryIdx)
                      entry.categories.push(-1);

                    //console.log(sCell.text);
                    //console.log(this.parseTime(sCell.text));

                    if (sCell.text.includes('km/h'))
                      entry.categories[lastEntryIdx] = 1;
                    else if (this.parseTime(sCell.text) != null)
                      entry.categories[lastEntryIdx] = 0;
                  }
                }
              }
            }

            result.push(entry);
          }
      }

    //console.log(result);

    return result;
  }





  static parseTime(t)
  {
    var regExp = /(([0-9]{1,2}):){0,1}(([0-9]{1,2}):){0,1}([0-9]{1,2})(.([0-9]{1,6})){0,1}/
    return t.match(regExp);
  }

  static parseSpeed(s)
  {
    var regExp = /(.*) km\/h/;
    //var regExp = /([+-]?([0-9]*[.])?[0-9])+ km\/h/;
   
    return s.match(regExp);   
  }

  static convertTimeToSeconds(t)
  {
    var parts = this.parseTime(t);

    if (parts == null)
      return 0;

    var h = parts[2] && parts[4] ? parts[2] : '00';
    var m = parts[2] && parts[4] ? parts[4] : (parts[2] ? parts[2] : '00');
    var s = parts[5] ? parts[5] : '00';
    var frac = parts[7] ? parts[7] : 0;

    var seconds = h * 3600 + m * 60 + s * 1;
    seconds = seconds + '.' + frac;
    
    //console.log(t);
    //console.log(parts);
    return seconds;
  }

  static convertSpeedToFloat(s)
  {
    let parts = this.parseSpeed(s);
    //console.log(s);
    //console.log(parts);

    if (parts == null)
      return 0;

    return parts[1];
  }






  static createChartData(currentContent)
  {
    const sectorData = this.extractSectorData(currentContent);

    if (sectorData.length === 0)
      return [];

    var sprintf = require('sprintf-js').sprintf;

    // create clusters as the max value of all clusters of all involved entries
    let clusters = Array(sectorData[0].categories.length).fill(-1);
    for (let sd of sectorData)
      for (let [cIdx, cat] of sd.categories.entries())
        if (cat > clusters[cIdx])
          clusters[cIdx] = cat;

    let tickFunc = Array(2);
    tickFunc[0] = function(value, index, values) {
      //return value + ' sec';
      let h = Math.floor(value / 3600); 
      let m = Math.floor((value % 3600) / 60);
      let s = value % 60;
      let result = '';
      if (h > 0)
        result = sprintf('%02d', h) + ':';
      if (h > 0)
        result = result + sprintf('%02d', m) + ':';
      else
        result = m + ':';
      result = result + sprintf('%02d', s);

      return result;
    }; 
    tickFunc[1] = function(value, index, values) {
      return value + ' km/h';
    };

    let titles = ['Sektoren', 'Geschwindigkeiten'];
    //clusters = [0,2,0,2,0,1,2,1,2];

    let maxClusterIdx = 0;
    for (let c of clusters)
      if (c > maxClusterIdx)
        maxClusterIdx = c;

    let charts = [];
    for (let x = 0; x <= maxClusterIdx; x++)
    {
      let currTickFunc = (x < tickFunc.length) ? tickFunc[x] : tickFunc[0];
      charts.push({
        datasets : [],
        labels : [],
        initialized : false,
        options : {
          scales: {
            y: {
              ticks: {
                callback: currTickFunc
              }
            }
          },
          plugins: {
            tooltip: {
              callbacks: {
                label: function (context) {
                  return context.dataset.label + ': '
                      + context.dataset.originalData[context.dataIndex];
                }
              }
            }
          }
        }
      });
    }

    let finalSectorData = sectorData;
    // if there is only one competitor but multiple heats
    if (sectorData.length === 1 && sectorData[0].heats.length > 1) {
      let newSectorData = [];
      let heats = sectorData[0].heats;
      sectorData[0].heats = [];
      newSectorData.push(sectorData[0]);
      for (let x = 1; x < heats.length; x++)
        newSectorData.push(JSON.parse(JSON.stringify(sectorData[0])));

      for (let x = 0; x < heats.length; x++) {
        newSectorData[x].title = "Lauf " + heats[x].name;
        newSectorData[x].heats = [heats[x]];
      }
      finalSectorData = newSectorData;
    }

    for (let [eIdx, entry] of finalSectorData.entries())
    {
      //const bgColor = (eIdx == 0) ? 'rgba(255, 0, 0, 0.1)' : 'rgba(0, 255, 0, 0.1)';
      const bdColor = (eIdx === 0) ? 'rgba(127, 0, 0, 0.3)' : 'rgba(0, 127, 0, 0.3)';
      const bgColor = (eIdx === 0) ? 'rgba(127, 0, 0, 0.5)' : 'rgba(0, 127, 0, 0.5)';

      //console.log(bgColor);
      let chartDatasets = [];
      for (let chart of charts)
        chartDatasets.push({
          label : entry.title,
          backgroundColor : bgColor,
          borderColor : bdColor,
          data : [],
          originalData : []
        });

      if (!charts[0].initialized)
      {
        charts[0].initialized = true;
        for (let [idx, label] of entry.sectorNames.entries())
        {
          if (clusters[idx] >= 0)
            charts[clusters[idx]].labels.push(label);
        }
      }

      if (entry.heats.length > 0)
      {
        for (let [idx, dataEntry] of entry.heats[0].data.entries())
        {
          let content = (dataEntry.includes('km/h')) ? this.convertSpeedToFloat(dataEntry) : this.convertTimeToSeconds(dataEntry);
          //console.log(dataEntry + ' - ' + content);
          if (clusters[idx] >= 0)
          {
            chartDatasets[clusters[idx]].data.push(content);
            chartDatasets[clusters[idx]].originalData.push(dataEntry);
          }
        }
      }

      for (let [cIdx, chartDS] of chartDatasets.entries())
        if (chartDS.data.length > 0)
          charts[cIdx].datasets.push(chartDS);
    }

    let result = [];
    for (let [cIdx, chart] of charts.entries())
      if (chart.datasets.length > 0)
        result.push({title : titles[cIdx], data : chart});

    //console.log(result);
    
    return result;
  }
}
