import RaceDependentState from "@/js-code/states/abstract/raceDependentState";

export default class TickerState extends RaceDependentState {
  constructor(race, maxItems, getItems, setItems) {
    super(race);

    this.maxItems = maxItems;
    this.getItems = getItems;
    this.setItems = setItems;
  }

  filter(items)
  {
    if (items.length <= this.maxItems)
      return items;
    return items.slice(0, this.maxItems);
  }

  prepend(oldItems, newItems)
  {
    let result = oldItems;
    for (let element of newItems)
      result.unshift(element);
    return result;
  }

  updateContent(content)
  {
    this.setItems(this.filter(content.reverse()));
  }

  mergeContent(content)
  {
    this.setItems(this.filter(this.prepend(this.getItems(), content)));
    return true;
  }
}