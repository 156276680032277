import { translationTableBackendMessages } from "@/mixins/translations/ErrorMessages.js"

export default class MessageFormatter {
  constructor(storeContext)
  {
    this.storeContext = storeContext;
  }

  format(code)
  {
    const langId = this.storeContext.rootGetters['language/current'].id;
    const map = translationTableBackendMessages.data().translation;

    //console.log(langId);
    //console.log(map);

    return MessageFormatter.findTranslatedMessage(map, langId, code);
  }

  static findTranslatedMessage(map, langId, code)
  {
    let message = `Es ist ein Fehler (${code}) aufgetreten`;
    if (map[langId] && map[langId][code])
      return map[langId][code];
    if (langId !== 'DEU' && map['ENG'] && map['ENG'][code])
      return map['ENG'][code];
    if (map['DEU'] && map['DEU'][code])
      return map['DEU'][code];

    return message;
  }

  static translateText(map, langId, text)
  {
    if (map[langId] && map[langId][text])
      return map[langId][text];
    if (langId !== 'DEU' && map['ENG'] && map['ENG'][text])
      return map['ENG'][text];
    return text;
  }
}