<template>
  <div class="eventStatus" :class="specificClass">
   <span><b-icon :visible="icon.visible" :icon="icon.name" :animation="icon.animation" font-scale="2" style="margin-right: 5px;" /> {{ message }}</span>
  </div>
</template>

<script>
import { translationMixin } from "@/mixins/translationMixin.js";
import { translationTableMixin } from "@/mixins/translations/EventStatus.js";

export default {
  mixins: [translationMixin, translationTableMixin],
  data: function() {
    return {
      message: '',
      specificClass: '',
      icon : {
        name : 'arrow-clockwise',
        animation : 'spin',
        visible : true,
      }
    }
  },
  methods: {
    displayWaiting: function () {
      this.message = this.t('Warte auf Daten') + ' ';
      this.icon = {
        name : 'arrow-clockwise',
        animation : 'spin',
        visible : true,    
      };
      this.specificClass = 'eventStatusUpdate';
    },

    displayOk: function () {
      this.message = this.t('Veranstaltung läuft');
      this.icon = {
        name : 'collection-play',
        animation : 'fade',
        visible : true,    
      };
      this.specificClass = 'eventStatusOk';
    },

    displayNoConnection: function() {
      this.message = this.t('keine Verbindung');
      this.icon = {
        name : 'server',
        animation : '',
        visible : true,    
      };
      this.specificClass = 'eventStatusConnection';
    },

    displayNoData: function() {
      this.message = this.t('keine Daten');
      this.icon = {
        name : 'server',
        animation : '',
        visible : true,    
      };
      this.specificClass = 'eventStatusConnection';
    },

    displayInterruption: function() {
      this.message = this.t('Unterbrechung');

      if (this.someoneDidNotFinish) {
        this.icon = {
          name: 'tools',
          animation: 'spin',
          visible: true,
        };
      }
      else {
        this.icon = {
          name: 'pause-fill',
          animation: 'throb',
          visible: true,
        };
      }
      this.specificClass = 'eventStatusUpdate';
    },

    displayClientProblem: function() {
      this.message = this.t('Internet-Problem');
      this.icon = {
        name : 'plug',
        animation : 'throb',
        visible : true,    
      };
      this.specificClass = 'eventStatusHTTP';
    },

    displayState: function (state) {
      switch (state) {
        case 'no connection': this.displayNoConnection(); break;
        case 'no data': this.displayNoData(); break;
        case 'active': this.displayOk(); break;
        case 'interruption': this.displayInterruption(); break;
        case 'internet': this.displayClientProblem(); break;
      default:
        this.displayNoData();
      }
    }
  },
  computed: {
    eventState() {
      return this.$store.getters['liveTiming/eventState'];
    },
    someoneDidNotFinish() {
      const result = JSON.parse(this.$store.getters['liveTiming/results']('RecentAborts'));
      return result
          && result.groups.length > 0
          && result.groups[0].subGroups.length > 0 
          && result.groups[0].subGroups[0].table.datasets
          && result.groups[0].subGroups[0].table.datasets.length > 0;
    }
  },
  watch: {
    currentLanguage(newValue, oldValue) {
      if (oldValue === newValue)
        return;

      if (this.eventState)
        this.displayState(this.eventState);
      else
        this.displayWaiting();
    },
    eventState(newValue, oldValue) {
      if (oldValue === newValue)
        return;

      this.displayState(newValue);
    }
  },
  created() {
    if (this.eventState)
      this.displayState(this.eventState);
    else
      this.displayWaiting();
  }
}
</script>

<style scoped>
   .eventStatus {
      width: 150px;
      height: 10px;
      margin-top: 10px;
      margin-bottom: 10px;
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 15px;
      padding-bottom: 15px;
      background-color: red;
      border-top: 1px dotted #AA0000;
      border-bottom: 1px dotted #AA0000;
      color: white;
      text-align: center;
      font-size: 12px;
      font-weight: bold;
      font-family: Arial;
   }

   .eventStatus span {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
   }

   .eventStatusOk {
     background-color: green;
   }

   .eventStatusHTTP {
     background-color: red;
     color: yellow;
   }

   .eventStatusConnection {
    }

   .eventStatusUpdate {
     background-color: #DDAA00;
   }
</style>