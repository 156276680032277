export default class NonRaceDependentState {
  constructor()
  {
    this.stateIndicator = -1;
  }

  updateAll(specifier, stateIndicator, content)
  {
    this.stateIndicator = stateIndicator;
    this.updateContent(content);

    //console.log('current indicator: ' + this.stateIndicator)
  }

  merge(specifier, previousIndicator, currentIndicator, content)
  {
    //console.log(content);
    //console.log('current indicator: ' + currentIndicator);
    if (previousIndicator === this.stateIndicator)
    {
      if (!this.mergeContent(content))
        return {merged: false, stateIndicator: this.stateIndicator};
      this.stateIndicator = currentIndicator;
      //console.log('previous indicator: ' + previousIndicator);
      //console.log('current indicator: ' + currentIndicator);
      //console.log('this.stateIndicator: ' + this.stateIndicator);
    }
    else {
      // console.log('not merged!');
      return {merged: false, stateIndicator: this.stateIndicator};
    }

    return {merged: true};
  }
}