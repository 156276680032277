//import moment from 'moment';

export default class WeatherClient {
  constructor(storeContext) {
    this.storeContext = storeContext;

    this.namespace = '/weather';
    this.interval = window.setInterval(this.updateWeather.bind(this), 5 * 60000);
    this.updateWeather();
  }

  beforeDestroy() {
    window.clearInterval(this.interval);
  }

  async updateWeather()
  {
    //console.log(moment(Date.now()).format('HH:mm'));
    //console.log("update weather");
    const backend = this.storeContext.rootState.backend;
    const result = await backend.get(process.env.VUE_APP_BACKEND_URL + '/weather/currentRace');
    //console.log(result);
    if (result.status === 'ok')
    {
      let owmData = result.data;
      let data = JSON.parse(JSON.stringify(this.storeContext.getters['data']));

      this.storeContext.commit('setReceived', true);
      if (owmData && owmData.weather && owmData.weather.length > 0)
      {
        data.weather.id = owmData.weather[0].id;
        data.weather.main = owmData.weather[0].main;
        data.weather.description = data.weather.main;
        data.weather.icon = owmData.weather[0].icon;
      }
      if (owmData.main)
      	data.temp = Math.floor(owmData.main.temp);
      if (owmData.wind)
      {
      	data.wind.speed = owmData.wind.speed;
      	data.wind.deg = owmData.wind.deg;
      }

      this.storeContext.commit('setData', data);
    }
  }
}