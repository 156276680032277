export default class DownloadsClient {
  constructor(storeContext) {
    this.storeContext = storeContext;
    this.interval = window.setInterval(this.updateDownloads.bind(this), 30000);
    this.updateDownloads();
  }

  beforeDestroy()
  {
    window.clearInterval(this.interval);
  }

  async updateDownloads()
  {
    const backend = this.storeContext.rootState.backend;
    const result = await backend.get(process.env.VUE_APP_BACKEND_URL + '/downloads/list');
    if (result.status === 'ok')
      this.storeContext.commit('setItems', result.data);
  }
}