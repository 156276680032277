<template>
  <div class="ticker">
    <blockTitle>{{ t('Ticker') }}</blockTitle>
    <div class="messages">
      <div v-for="(item, index) in items" :key="item.id" class="message">
        <hr class="msgBorder" v-if="index > 0" />
        <div class="msgTitle">
         <span class="time">{{ item.time | formatTime('HH:mm') }}:</span>
         <span class="name">{{ item.name }}</span>
        </div>
        {{ item.msg }}
      </div>
    </div>
  </div>
</template>

<script>
import { translationMixin } from "@/mixins/translationMixin.js";
import { translationTableMixin} from "@/mixins/translations/Ticker.js";

export default {
  mixins: [translationMixin, translationTableMixin],
  computed: {
    items() {
      return this.$store.getters['ticker/items'];
    }
  },
}
</script>

<style scoped>
.ticker {
  margin-top: 15px;
  width: 100%;
}

.messages {
  border-top: 1px dotted #cbccdd;
  border-bottom: 1px dotted #cbccdd;
  background: #edeeff;
  width: 100%;
  font-size: 10.5pt;
  text-align: left;
  padding-bottom: 5px;
}

.msgBorder {
  margin: 0px;
  margin-bottom: 5px;
  width: calc(100% - 2px);
  border-top: 1px dashed #cbccdd;
  border-bottom: 1px dashed #cbccdd;
}

.message {
  padding-left: 5px;
  padding-top: 5px;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
}

.msgTitle {
}

.message .time {
  font-weight: bold;
}

.message .name {
  padding-left: 5px;
  color: red;
}

.message .msg {
  text-align: left;
}
</style>