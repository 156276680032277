<template>
  <div class="overviewcomponent ontrack">
   <table>
     <tr>
       <td style="width: 20px;" class="img">
         <div class="img">
            <div><img src="../../assets/overview/strecke.png" /></div>
          </div>
       </td>
       <td style="width: 180px">
         <div class="recent_aborts">
           <OverviewTable contentName="RecentAborts" :desc="false" />
         </div>
         <div class="ontrack_list">
           <OverviewTable contentName="Ontrack" :desc="true" />
         </div>
       </td>
     </tr>
   </table>
  </div>
</template>

<script>
import OverviewTable from "@/components/OverviewTable.vue";

export default {
  components: {
    OverviewTable
  },
  data() {
    return {
    }
  },
}
</script>

<style scoped>
@import "../../assets/overview/overview.css";

.recent_aborts {
  display: block;
  color: red;
  width: 100%;
}

.ontrack_list {
  display: block;
  width: 100%;
}

.ontrack {
  background-image: url(../../assets/overview/rennstrecke.png);
}
</style>